// theme.js
const theme = {
    openai: {
        headerBackground: 'bg-blue-100',
        headerHoverBackground: 'bg-blue-200',
        headerColor: 'text-blue-800',
        width: 'w-60'
    },
    anthropic: {
        headerBackground: 'bg-green-100',
        headerHoverBackground: 'bg-green-200',
        headerColor: 'text-green-800',
        width: 'w-60'
    },
    llama: {
        headerBackground: 'bg-purple-100',
        headerHoverBackground: 'bg-purple-200',
        headerColor: 'text-purple-800',
        width: 'w-60'
    },
    google_ai: {
        headerBackground: 'bg-orange-100',
        headerHoverBackground: 'bg-orange-200',
        headerColor: 'text-orange-800',
        width: 'w-60'
    },
    quantumwake: {
        headerBackground: 'bg-pink-100',
        headerHoverBackground: 'bg-pink-200',
        headerColor: 'text-pink-800',
    },
    state: {
        headerBackground: 'bg-amber-100',
        headerHoverBackground: 'bg-gray-200',
        headerColor: 'text-gray-800',
        width: 'w-64'
    },
    python: {
        headerBackground: 'bg-red-100',
        headerHoverBackground: 'bg-red-200',
        headerColor: 'text-red-800',
        width: 'w-60'
    },
    trainer: {
        headerBackground: 'bg-red-800',
        headerHoverBackground: 'bg-red-200',
        headerColor: 'text-white',
        width: 'w-60'
    },
    // Add more color definitions for other processors
};

export default theme;